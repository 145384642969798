/* ContactForm.css */
.contactForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* max-width:800px; */
    margin: auto;

    font-family: "raleway", sans-serif;
}

.contactForm textarea {
    resize: vertical; /* Allows only vertical resizing */
    
    padding: 10px;
    margin: 5px 0;
    border-radius: 0px;
    border: 1px solid #ccc;
}

.contactForm input {
    padding: 10px;
    margin: 5px 0;
    border-radius: 0px;
    border: 1px solid #ccc;

}

.contactForm button {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.contactForm button {
    background-color: #4eb478;
    color: white;
    border: none;
    cursor: pointer;
}

.contactForm button:hover {
    background-color: #4eb478;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .contactForm {
        width: 90%;
    }
}

.contactFormCard {
    /* max-width: 800px; */
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    
}

.formRow {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust gap between inputs as needed */
}

.formRow input {
    flex: 1; /* Causes inputs to fill the available space equally */
    min-width: 120px; /* Prevents inputs from becoming too small on narrow screens */
}



/* Styles for the button when it is disabled */
.contactForm button:disabled {
    background-color: #cccccc; /* Light grey to indicate it's disabled */
    color: #666666; /* Dark grey text */
    cursor: not-allowed; /* Cursor indicates the button cannot be clicked */
    opacity: 0.7; /* Slightly transparent to enhance the disabled look */
}